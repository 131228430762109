import React, { useCallback } from "react";
import { ColumnDef } from "@tanstack/react-table";

import { SORT_BY_ADDED_DATE_PARAMETER, SORT_BY_NAME_PARAMETER } from "../../constants/pagination";
import { hasActionablePendingTasks } from "../../helpers/pending-tasks.helpers";
import useCustomersListParams from "../../hooks/useCustomersListParams";
import { Customer } from "../../types/utilities";
import ReactTable from "../ReactTable/ReactTable";
import { SORT_DIRECTION } from "../ReactTable/types";

import CustomersListPagination from "./CustomersListPagination";

interface CustomersListTableProps {
  columns: ColumnDef<Customer>[];
  customers: Customer[];
  count: number;
  isLoading?: boolean;
  hidePagination?: boolean;
  additionalPaginationOnTop?: boolean;
  sortLocally?: boolean;
  selectable?: boolean;
  selectedIds?: string[];
  onSelectionChange?(customersIds: string[]): void;
}

const CustomersListTable = (props: CustomersListTableProps) => {
  const {
    columns,
    customers,
    count,
    isLoading,
    hidePagination,
    additionalPaginationOnTop,
    sortLocally,
    selectable,
    selectedIds,
    onSelectionChange,
    ...rest
  } = props;

  const { order, setOrder, setPage } = useCustomersListParams();

  const getRowId = useCallback((customer: Customer) => customer.id, []);

  const handleSortChange = useCallback(
    (columnId: string, sortDirection: SORT_DIRECTION) => {
      const orderDirection = sortDirection === "ascending" ? "" : "-";
      const newOrder = orderDirection + columnId;

      setOrder(newOrder);
      setPage(0);
    },
    [setOrder, setPage]
  );

  const sortedColumnId = order?.includes(SORT_BY_NAME_PARAMETER)
    ? SORT_BY_NAME_PARAMETER
    : SORT_BY_ADDED_DATE_PARAMETER;
  const sortedDirection = !order || order.startsWith("-") ? "descending" : "ascending";

  const showPagination = !hidePagination && customers.length > 0;

  return (
    <ReactTable<Customer>
      columns={columns}
      data={customers}
      getRowId={getRowId}
      getRowProps={(customer) => (hasActionablePendingTasks(customer) ? { status: "info" } : { status: "regular" })}
      sortLocally={sortLocally}
      sortedColumnId={sortedColumnId}
      sortedDirection={sortedDirection}
      onSortChange={handleSortChange}
      selectedIds={selectedIds || []}
      onSelectionChange={onSelectionChange}
      selectable={selectable}
      isLoading={isLoading}
      headerContent={
        showPagination && additionalPaginationOnTop ? (
          <CustomersListPagination isLoading={isLoading} customers={customers} count={count} />
        ) : null
      }
      footerContent={
        showPagination ? <CustomersListPagination isLoading={isLoading} customers={customers} count={count} /> : null
      }
      {...rest}
    />
  );
};

export default CustomersListTable;
