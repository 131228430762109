export enum MARKED_AS_FILTERS {
  PEP = "PEP",
  SANCTIONED = "SANCTIONED"
}

export enum SCREENING_TYPE_FILTERS {
  MONITORING = "MONITORING",
  ONE_TIME = "ONE_TIME"
}

/**
 * Start interval cannot be before data was started to be gathered
 */
export const MIN_DATE_FILTER = new Date("2020-01-01");

/**
 * End interval cannot be higher than today
 */
export const MAX_DATE_FILTER = new Date();
