import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from "react";
import { Checkbox } from "@shopify/polaris";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Header,
  isFunction,
  Row,
  RowSelectionState,
  SortingState,
  Updater,
  useReactTable
} from "@tanstack/react-table";
import styled from "styled-components";

import { MOBILE_BREAKPOINT } from "../../constants/styles";
import { SELECTION_COLUMN_ID } from "../../constants/tables";
import { isEmptyString } from "../../utils/stringUtils";
import { noop } from "../../utils/util";

import ReactTableSkeleton from "./ReactTableSkeleton";
import TableCell from "./TableCell";
import TableHeaderCell from "./TableHeaderCell";
import TableRow from "./TableRow";
import { ROW_OPTIONS, SORT_DIRECTION } from "./types";

interface ReactTableProps<T> {
  columns: ColumnDef<T>[];
  data: T[];
  sortLocally?: boolean;
  sortedColumnId?: string;
  sortedDirection?: SORT_DIRECTION;
  selectedIds?: string[];
  selectable?: boolean;
  expandable?: boolean;
  showTreeLines?: boolean;
  globalFilter?: string;
  isLoading?: boolean;
  headerContent?: ReactNode;
  footerContent?: ReactNode;
  getRowId(row: T): string;
  isRowSelectable?(row: T): boolean;
  getSubRows?(row: T): T[] | undefined;
  getRowProps?(row: T): ROW_OPTIONS | undefined;
  getRowLineLabel?(row: T): JSX.Element | undefined;
  onSortChange?(sortedColumnId?: string, sortedDirection?: SORT_DIRECTION): void;
  onSelectionChange?(selectedIds: string[], fromSelectAll?: boolean): void;
}

function ReactTable<T extends object>(props: ReactTableProps<T>): ReactElement {
  const {
    columns,
    data,
    sortLocally,
    sortedColumnId,
    sortedDirection,
    selectedIds = [],
    selectable,
    expandable,
    showTreeLines,
    globalFilter,
    isLoading,
    headerContent = null,
    footerContent = null,
    getRowId,
    isRowSelectable = () => selectable,
    getSubRows,
    getRowProps = () => ({}),
    getRowLineLabel,
    onSortChange = noop,
    onSelectionChange = noop,
    ...rest
  } = props;

  // when sorting locally there's no need to provide an initial sorting state
  const [localSortingState, setLocalSorting] = useState<SortingState>([]);

  // when not sorting locally, the sorting state is created from the component props
  const externalSortingState: SortingState | undefined =
    !sortLocally && sortedColumnId && sortedDirection
      ? [
          {
            id: sortedColumnId,
            desc: sortedDirection === "descending"
          }
        ]
      : undefined;

  const handleSortChange = useCallback(
    (header: Header<T, unknown>) => {
      if (sortLocally) {
        header.column.toggleSorting();
      } else {
        onSortChange(header.column.id, sortedDirection === "ascending" ? "descending" : "ascending");
      }
    },
    [sortLocally, onSortChange]
  );

  const selectionState: RowSelectionState = Object.fromEntries(selectedIds.map((id) => [id, true]));

  const handleSelectionChange = useCallback(
    (updater: Updater<RowSelectionState>) => {
      const updatedSelectionState = isFunction(updater) ? updater(selectionState) : updater;
      // TODO find a way to detect if the selection change was initiated from the header or from a row
      const fromSelectAll = false;

      onSelectionChange(
        Object.entries(updatedSelectionState)
          .filter(([, selected]) => selected)
          .map(([id]) => id),
        fromSelectAll
      );
    },
    [onSelectionChange, selectionState]
  );

  if (selectable) {
    columns.unshift({
      id: SELECTION_COLUMN_ID,
      size: 1,
      header: () => {
        const checkedState = table.getIsSomeRowsSelected() ? "indeterminate" : table.getIsAllRowsSelected();
        return (
          <Checkbox
            labelHidden
            label=""
            checked={checkedState}
            onChange={() =>
              table.toggleAllPageRowsSelected(table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected())
            }
          />
        );
      },
      cell: ({ row }) => (
        <Checkbox
          labelHidden
          label=""
          checked={row.getIsSelected()}
          disabled={!isRowSelectable(row.original)}
          onChange={row.getToggleSelectedHandler()}
        />
      )
    });
  }

  const table = useReactTable<T>({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: sortLocally ? getSortedRowModel() : undefined,
    getExpandedRowModel: expandable ? getExpandedRowModel() : undefined,
    getFilteredRowModel: getFilteredRowModel(),
    filterFromLeafRows: true,
    state: {
      sorting: externalSortingState || localSortingState,
      rowSelection: selectionState,
      globalFilter
    },
    defaultColumn: {
      size: 0,
      minSize: undefined
    },
    initialState: {
      // expand the root node by default
      expanded: true
    },
    manualSorting: !sortLocally,
    enableMultiSort: false,
    enableSortingRemoval: false,
    getSubRows,
    getRowId,
    enableRowSelection: (row: Row<T>) => Boolean(isRowSelectable(row.original)),
    onSortingChange: setLocalSorting,
    onRowSelectionChange: handleSelectionChange
  });

  // find the column name by trying to render the header
  // if it returns a string - use that
  // otherwise try the meta.label value
  const getColumnLabel = useCallback((columnId: string) => {
    const header = table
      .getHeaderGroups()
      .map((headersGroup) => headersGroup.headers)
      .flat()
      .find((header) => header.id === columnId);

    const headerLabel =
      !header || header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext());

    const columnLabel = // @ts-ignore
      (typeof headerLabel === "string" ? headerLabel : header?.column.columnDef.meta?.label || "") as string;

    return columnLabel;
  }, []);

  const visibleColumnsCount = columns.length - (selectable ? 1 : 0);

  const isExpandingColumn = useCallback(
    (index: number) => (expandable ? index === (selectable ? 1 : 0) : false),
    [expandable, selectable]
  );

  useEffect(() => {
    if (isEmptyString(globalFilter) || table.getIsAllRowsExpanded()) {
      return;
    }

    table.toggleAllRowsExpanded(true);
  }, [globalFilter, table]);

  return (
    <div {...rest}>
      <div className="Polaris-DataTable">
        {headerContent && <StyledHeader>{headerContent}</StyledHeader>}
        <div className="Polaris-DataTable__ScrollContainer">
          <StyledTable className="Polaris-DataTable__Table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, index) => (
                    <TableHeaderCell<T>
                      key={header.id}
                      header={header}
                      expandable={isExpandingColumn(index)}
                      onSortChange={handleSortChange}
                    />
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} {...getRowProps(row.original)} depth={row.depth} expanded={row.getIsExpanded()}>
                  {row.getVisibleCells().map((cell, index) => (
                    <TableCell
                      key={cell.id}
                      cell={cell}
                      columnLabel={getColumnLabel(cell.column.id)}
                      isExpandableCell={isExpandingColumn(index)}
                      showTreeLines={showTreeLines}
                      getRowLineLabel={getRowLineLabel}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              {isLoading && <ReactTableSkeleton columns={visibleColumnsCount} />}
            </tbody>
          </StyledTable>
          {footerContent && <div className="Polaris-DataTable__Footer">{footerContent}</div>}
        </div>
      </div>
    </div>
  );
}

const StyledTable = styled.table`
  // For small viewports - set background color to light gray in order to improve rows gaps
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    background-color: var(--p-color-bg-app);
  }
`;

const StyledHeader = styled.div`
  padding: var(--p-space-4);
  background: var(--p-color-bg-subdued);
  color: var(--p-color-text-subdued);
  text-align: center;
  border-bottom-left-radius: var(--p-border-radius-2);
  border-bottom-right-radius: var(--p-border-radius-2);
`;

export default ReactTable;
