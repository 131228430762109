import React from "react";
import { FilterInterface, Filters, VerticalStack } from "@shopify/polaris";

import api from "../../api";
import { AppliedFilter, createFilter } from "../../helpers/filters.helpers";
import { getTagTitle } from "../../helpers/tag.helpers";
import useDashboardDateAppliedFilters from "../../hooks/useDashboardDateAppliedFilters";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetUserName from "../../hooks/useGetUserName";
import useShowResponsibleUserFilter from "../../hooks/useShowResponsibleUserFilter";
import useTags from "../../hooks/useTags";
import { toggleElement } from "../../utils/collectionUtils";
import { noop } from "../../utils/util";
import AppliedFilters from "../AppliedFilters/AppliedFilters";
import { ALL_FILTER_ITEM_VALUE } from "../CustomersListFilters/useFiltersOptions";
import DashboardDateIntervalFilter from "../DashboardDateIntervalFilter/DashboardDateIntervalFilter";
import TagsFilter from "../TagsFilter/TagsFilter";
import UsersList from "../UsersList/UsersList";

type DashboardFiltersProps = {
  isLoading?: boolean;
  responsibleUserIds: string[];
  tagIds: string[];
  customerType?: api.DashboardCustomerType;
  dateInterval?: api.DashboardDateInterval;
  startDate?: string;
  endDate?: string;
  setResponsibleUserIds(responsibleUserIds: string[]): void;
  setTagIds(tagIds: string[]): void;
  setCustomerType(customerType?: api.DashboardCustomerType): void;
  setDateInterval(dateInterval?: api.DashboardDateInterval): void;
  setStartDate(startDate?: string): void;
  setEndDate(startDate?: string): void;
};

const DashboardFilters = (props: DashboardFiltersProps) => {
  const {
    isLoading,
    responsibleUserIds,
    tagIds,
    customerType,
    setResponsibleUserIds,
    setTagIds,
    setCustomerType,
    ...dateFilters
  } = props;

  const f = useFormatMessage();
  const features = useFeatures();
  const { findTag } = useTags();
  const { getUserFullName } = useGetUserName(features.RESPONSIBLE_USER);

  const showResponsibleUserFilter = useShowResponsibleUserFilter();

  const appliedCreatedDateFilters = useDashboardDateAppliedFilters(dateFilters);

  const filters: FilterInterface[] = [];
  const appliedFilters: AppliedFilter[] = [];

  filters.push({
    key: "tag",
    label: f("component.filters.tags.title"),
    shortcut: true,
    hideClearButton: true,
    filter: <TagsFilter tagIds={tagIds} setTagIds={setTagIds} />
  });

  // find Tags from ids, filter missing tags, sort by tag title and add to applied filters
  tagIds
    .map(findTag)
    .filter(Boolean)
    .sort((tagA, tagB) => getTagTitle(tagB).localeCompare(getTagTitle(tagA)))
    .forEach((tag) => {
      appliedFilters.unshift({
        key: "tag-" + tag.id,
        label: getTagTitle(tag),
        tagColor: tag.color,
        isTag: true,
        onRemove: () => setTagIds(tagIds.filter((id) => id !== tag.id))
      });
    });

  if (showResponsibleUserFilter) {
    filters.push({
      key: "responsible-user",
      label: f("responsible.label"),
      shortcut: true,
      hideClearButton: true,
      filter: (
        <UsersList
          showAllUsersOption
          selectedUserIds={responsibleUserIds}
          allowMultiple
          onChange={setResponsibleUserIds}
        />
      )
    });
  }

  const [customerTypeFilter, customerTypeAppliedFilter] = createFilter<api.DashboardCustomerType>(
    "customer-type",
    f("component.filter.customer.title"),
    customerType,
    false,
    [
      { label: f("component.filters.all.title"), value: ALL_FILTER_ITEM_VALUE },
      { label: f("component.filter.customer.option.company.title"), value: "COMPANY" },
      { label: f("component.filter.customer.option.person.title"), value: "PERSON" }
    ],
    setCustomerType
  );

  filters.push(customerTypeFilter);

  if (customerTypeAppliedFilter) {
    appliedFilters.push(customerTypeAppliedFilter);
  }

  const dateFilter: FilterInterface = {
    key: "date-interval",
    label: f("dashboard.filters.date.title"),
    shortcut: true,
    hideClearButton: true,
    filter: <DashboardDateIntervalFilter isLoading={isLoading} {...dateFilters} />
  };

  filters.push(dateFilter);

  appliedFilters.push(
    ...responsibleUserIds.map((userId) => ({
      key: "responsible-user",
      label: `${f("responsible.label")}: ${getUserFullName(userId)}`,
      onRemove: () => setResponsibleUserIds(toggleElement(responsibleUserIds, userId))
    }))
  );

  appliedFilters.push(...appliedCreatedDateFilters);

  return (
    <VerticalStack gap="2" inlineAlign="stretch">
      <Filters
        queryValue=""
        filters={filters}
        appliedFilters={appliedFilters}
        hideTags
        hideQueryField
        onQueryChange={noop}
        onQueryClear={noop}
        onClearAll={noop}
      />
      <AppliedFilters appliedFilters={appliedFilters} />
    </VerticalStack>
  );
};

export default DashboardFilters;
