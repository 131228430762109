import { useEffect, useState } from "react";
import {
  Checkbox,
  Collapsible,
  HorizontalStack,
  InlineError,
  RadioButton,
  Text,
  VerticalStack
} from "@shopify/polaris";

import ProjectIcon from "../../../assets/icons/project.svg";
import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import { first } from "../../utils/collectionUtils";
import Icon from "../extensions/Icon";

type AssignmentSelectorProps = {
  assignments?: api.Assignment[];
  selectedAssignment?: api.Assignment;
  readonly?: boolean;
  hideIcon?: boolean;
  forceShowError?: boolean;
  onSelectionChange(assignment: api.Assignment | undefined, isValidSelection: boolean): void;
};

const AssignmentSelector = (props: AssignmentSelectorProps) => {
  const { assignments, selectedAssignment, readonly, hideIcon, forceShowError, onSelectionChange } = props;

  const f = useFormatMessage();
  const [showAssignments, toggleAssignments] = useOpenClose(selectedAssignment !== undefined);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (assignments && assignments.length > 0 && !showAssignments) {
      toggleAssignments();
    }
  }, [assignments?.length]);

  useEffect(() => {
    // if the include assignment checkbox is checked and there is more than 1 assignment = an assignment must be selected
    // When there is only 1 assignment, the showAssignments checkbox is not rendered
    // only a single assignment checkbox
    const isValidSelection = showAssignments && assignments?.length !== 1 ? selectedAssignment !== undefined : true;

    onSelectionChange(undefined, isValidSelection);
  }, [showAssignments]);

  const handleSelectionChange = (assignment?: api.Assignment) => {
    setTouched(true);

    // if the include assignment checkbox is checked and there is more than 1 assignment = an assignment must be selected
    // When there is only 1 assignment, the showAssignments checkbox is not rendered
    // only a single assignment checkbox
    const isValidSelection = showAssignments && assignments?.length !== 1 ? assignment !== undefined : true;

    onSelectionChange(assignment, isValidSelection);
  };

  if (!assignments || assignments.length === 0) {
    return null;
  }

  const showError = (touched && showAssignments && !selectedAssignment) || forceShowError;

  return (
    <VerticalStack gap="2">
      <HorizontalStack gap="1" blockAlign="center" wrap={false}>
        {!hideIcon && <Icon source={ProjectIcon} width="16px" height="12px" />}
        <Text as="span" fontWeight="semibold">
          {f("customer-assignments.modal.assignment-name")}
        </Text>
      </HorizontalStack>
      {assignments.length === 1 && (
        <Checkbox
          label={first(assignments)?.name || ""}
          checked={selectedAssignment !== undefined}
          onChange={(newChecked) => handleSelectionChange(newChecked ? first(assignments) : undefined)}
          disabled={readonly}
        />
      )}

      {assignments.length > 1 && (
        <>
          <Checkbox
            label={f("common.labels.include-assignment")}
            checked={showAssignments}
            onChange={toggleAssignments}
            disabled={readonly}
          />
          <Collapsible id="show_assignments_collapsible" open={showAssignments}>
            <VerticalStack gap="2">
              {assignments.map((assignment) => (
                <RadioButton
                  label={assignment.name}
                  checked={selectedAssignment?.id === assignment.id}
                  onChange={() => handleSelectionChange(assignment)}
                  disabled={readonly}
                  key={assignment.id}
                />
              ))}
            </VerticalStack>
          </Collapsible>
          {showError && (
            <InlineError message={f("assignment.selector.error.message")} fieldID={"show_assignments_collapsible"} />
          )}
        </>
      )}
    </VerticalStack>
  );
};

export default AssignmentSelector;
