import api from "../api";
import {
  ARCHIVED_NAME_PARAMETER,
  COUNTRY_CODE_NAME_PARAMETER,
  CUSTOMER_FILTER_STATUS_PARAMS_TYPE,
  CUSTOMER_INDUSTRY_CODES_NAME_PARAMETER,
  CUSTOMER_TYPE_NAME_PARAMETER,
  CUSTOMER_TYPE_PARAMS,
  FOLLOW_UP_EXPIRED_NAME_PARAMETER,
  MARKED_AS_PEP_NAME_PARAMETER,
  MARKED_AS_SANCTIONED_NAME_PARAMETER,
  OWNERSHIP_UPDATES_NAME_PARAMETER,
  PENDING_MEASURES_NAME_PARAMETER,
  PENDING_TASKS_TAB_NAME_PARAMETER,
  RESPONSIBLE_USERS_NAME_PARAMETER,
  RISK_NAME_PARAMETER,
  SCREENING_TYPE_NAME_PARAMETER,
  STATUS_NAME_PARAMETER,
  TAGS_NAME_PARAMETER,
  UNRESOLVED_NAME_PARAMETER
} from "../constants/customers-list-search-params";
import { MARKED_AS_FILTERS, MAX_DATE_FILTER, MIN_DATE_FILTER } from "../constants/filters";
import {
  DateRange,
  FOLLOWUP_AFTER_NAME_PARAMETER,
  FOLLOWUP_BEFORE_NAME_PARAMETER,
  FOLLOWUP_RANGE_NAME_PARAMETER
} from "../constants/pagination";
import { Concrete } from "../types/utilities";
import { getRangeDate, isValidDate } from "../utils/dateUtils";
import { isEmptyString } from "../utils/stringUtils";
import { removeEmptyValues } from "../utils/util";

import useListParams from "./useListParams";
import { useBooleanURLSearchParam, useURLSearchParam, useURLSearchParams } from "./useURLSearchParam";

type CustomersListParams = Concrete<Parameters<typeof api.getCustomers>[0]>;
const useCustomersListParams = () => {
  const {
    order,
    setOrder,
    page,
    setPage,
    limit,
    setLimit,
    q,
    setQ,
    createdRange,
    setCreatedRange,
    createdAfter,
    setCreatedAfter,
    createdBefore,
    setCreatedBefore,
    searchParams: baseSearchParams,
    isEmptySearch: baseIsEmptySearch,
    resetAllFilters: baseResetAllFilters
  } = useListParams();

  const [status, setStatus] = useURLSearchParam<CUSTOMER_FILTER_STATUS_PARAMS_TYPE>(STATUS_NAME_PARAMETER);
  const [customerType, setCustomerType] = useURLSearchParam<CUSTOMER_TYPE_PARAMS>(CUSTOMER_TYPE_NAME_PARAMETER);
  const [risk, setRisk] = useURLSearchParam(RISK_NAME_PARAMETER);
  const [responsibleUserIds, setResponsibleUserIds] = useURLSearchParams(RESPONSIBLE_USERS_NAME_PARAMETER);
  const [isArchived, setIsArchived] = useBooleanURLSearchParam(ARCHIVED_NAME_PARAMETER);
  const [unresolved, setUnresolved] = useBooleanURLSearchParam(UNRESOLVED_NAME_PARAMETER);
  const [isPep, setIsPep] = useBooleanURLSearchParam(MARKED_AS_PEP_NAME_PARAMETER);
  const [isSanctioned, setIsSanctioned] = useBooleanURLSearchParam(MARKED_AS_SANCTIONED_NAME_PARAMETER);
  const [screeningType, setScreeningType] = useURLSearchParam(SCREENING_TYPE_NAME_PARAMETER);
  const [countryCode, setCountryCode] = useURLSearchParam<api.CountryEnum>(COUNTRY_CODE_NAME_PARAMETER);
  const [industryCodes, setIndustryCodes] = useURLSearchParams<api.IndustryCodeEnum>(
    CUSTOMER_INDUSTRY_CODES_NAME_PARAMETER
  );
  const [pendingTasks, setPendingTasks] = useBooleanURLSearchParam(PENDING_TASKS_TAB_NAME_PARAMETER);
  const [tagIds, setTagIds] = useURLSearchParams(TAGS_NAME_PARAMETER);
  const [haveOwnershipUpdates, setHaveOwnershipUpdates] = useBooleanURLSearchParam(OWNERSHIP_UPDATES_NAME_PARAMETER);
  const [followupRange, setFollowupRange] = useURLSearchParam<DateRange>(FOLLOWUP_RANGE_NAME_PARAMETER);
  const [followupAfter, setFollowupAfter] = useURLSearchParam(FOLLOWUP_AFTER_NAME_PARAMETER);
  const [followupBefore, setFollowupBefore] = useURLSearchParam(FOLLOWUP_BEFORE_NAME_PARAMETER);
  const [isFollowupExpired, setIsFollowupExpired] = useBooleanURLSearchParam(FOLLOW_UP_EXPIRED_NAME_PARAMETER);
  const [isPendingMeasures, setIsPendingMeasures] = useBooleanURLSearchParam(PENDING_MEASURES_NAME_PARAMETER);

  // runs the setter methods and resets the page to 0
  // this is done so only when the user changes a filter the page will be reset to 0
  // if we were to use "useEffect" it will cause the page to reset when restoring the location.search
  function resetPageWrapper<P, R>(setter: (props: P) => R) {
    return (props: P) => {
      setter(props);
      setPage(0);
    };
  }

  const markedAsFilters: string[] = [];
  if (isPep) {
    markedAsFilters.push(MARKED_AS_FILTERS.PEP);
  }
  if (isSanctioned) {
    markedAsFilters.push(MARKED_AS_FILTERS.SANCTIONED);
  }

  const handleMarkedAsFiltersChange = (filters: string[]) => {
    // Check the filtered items to toggle them
    setIsPep(filters.includes(MARKED_AS_FILTERS.PEP));
    setIsSanctioned(filters.includes(MARKED_AS_FILTERS.SANCTIONED));
  };

  const handleSetFollowupRange = (range: DateRange) => {
    if (range !== DateRange.CUSTOM) {
      setFollowupAfter(undefined);
      setFollowupBefore(undefined);
    }
    setFollowupRange(range);
  };

  const followupBeforeValue = getRangeDate(followupRange, followupBefore, false);

  const validFollowUpAfter = isValidDate(followupAfter, MIN_DATE_FILTER, MAX_DATE_FILTER) ? followupAfter : undefined;
  const validFollowUpBefore = isValidDate(followupBeforeValue, MIN_DATE_FILTER, MAX_DATE_FILTER)
    ? followupBeforeValue
    : undefined;

  const searchParams = removeEmptyValues<CustomersListParams>({
    ...baseSearchParams,
    status,
    hasPendingTasks: pendingTasks ? "1" : undefined,
    $type: customerType,
    riskLevel: risk,
    isArchived: isArchived ? "1" : undefined,
    hasUnresolvedCases: unresolved ? "1" : undefined,
    isPep: isPep ? "1" : undefined,
    isSanctioned: isSanctioned ? "1" : undefined,
    screeningType: screeningType as api.ScreeningType,
    countryCode: countryCode,
    industryCodes: industryCodes.length === 0 ? undefined : industryCodes.join(","),
    labels: tagIds.length === 0 ? undefined : tagIds.join(","),
    responsibleUsers: responsibleUserIds.length === 0 ? undefined : responsibleUserIds.join(","),
    unhandledOwnershipUpdates: haveOwnershipUpdates ? "1" : undefined,
    followUpAfter: validFollowUpAfter,
    followUpBefore: validFollowUpBefore,
    isFollowUpExpired: isFollowupExpired ? "1" : undefined,
    hasPendingMeasures: isPendingMeasures ? "1" : undefined
  });

  const isEmptySearch =
    baseIsEmptySearch &&
    isEmptyString(risk) &&
    isEmptyString(customerType) &&
    isEmptyString(status) &&
    responsibleUserIds.length === 0 &&
    isEmptyString(screeningType) &&
    isEmptyString(countryCode) &&
    industryCodes.length === 0 &&
    !unresolved &&
    !isPep &&
    !isSanctioned &&
    !isArchived &&
    tagIds.length === 0 &&
    !haveOwnershipUpdates &&
    isEmptyString(followupRange) &&
    isEmptyString(validFollowUpAfter) &&
    isEmptyString(validFollowUpBefore) &&
    !isFollowupExpired &&
    !isPendingMeasures;

  const resetAllFilters = () => {
    baseResetAllFilters();
    setStatus(undefined);
    setCustomerType(undefined);
    setRisk(undefined);
    setResponsibleUserIds([]);
    setIsArchived(undefined);
    setIsPep(undefined);
    setUnresolved(undefined);
    setIsSanctioned(undefined);
    setScreeningType("");
    setCountryCode(undefined);
    setIndustryCodes([]);
    setTagIds([]);
    setHaveOwnershipUpdates(undefined);
    setFollowupRange(undefined);
    setFollowupAfter(undefined);
    setFollowupBefore(undefined);
    setIsFollowupExpired(undefined);
    setIsPendingMeasures(undefined);
  };

  return {
    order,
    setOrder,
    page,
    setPage,
    limit,
    setLimit,
    q,
    setQ,
    status,
    setStatus: resetPageWrapper(setStatus),
    customerType,
    setCustomerType: resetPageWrapper(setCustomerType),
    risk,
    setRisk: resetPageWrapper(setRisk),
    screeningType,
    setScreeningType: resetPageWrapper(setScreeningType),
    isArchived,
    setIsArchived: resetPageWrapper(setIsArchived),
    unresolved,
    setUnresolved: resetPageWrapper(setUnresolved),
    isPep,
    setIsPep: resetPageWrapper(setIsPep),
    isSanctioned,
    setIsSanctioned: resetPageWrapper(setIsSanctioned),
    responsibleUserIds,
    setResponsibleUserIds: resetPageWrapper(setResponsibleUserIds),
    countryCode,
    setCountryCode: resetPageWrapper(setCountryCode),
    industryCodes,
    setIndustryCodes: resetPageWrapper(setIndustryCodes),
    tagIds,
    setTagIds: resetPageWrapper(setTagIds),
    haveOwnershipUpdates,
    setHaveOwnershipUpdates: resetPageWrapper(setHaveOwnershipUpdates),
    createdRange,
    setCreatedRange: resetPageWrapper(setCreatedRange),
    createdAfter,
    setCreatedAfter: resetPageWrapper(setCreatedAfter),
    createdBefore,
    setCreatedBefore: resetPageWrapper(setCreatedBefore),
    followupRange,
    setFollowupRange: resetPageWrapper(handleSetFollowupRange),
    followupAfter,
    setFollowupAfter: resetPageWrapper(setFollowupAfter),
    followupBefore,
    setFollowupBefore: resetPageWrapper(setFollowupBefore),
    isFollowupExpired,
    setIsFollowupExpired: resetPageWrapper(setIsFollowupExpired),
    isPendingMeasures,
    setIsPendingMeasures: resetPageWrapper(setIsPendingMeasures),
    searchParams,
    markedAsFilters,
    handleMarkedAsFiltersChange,
    pendingTasks,
    setPendingTasks: resetPageWrapper(setPendingTasks),
    isEmptySearch,
    resetAllFilters
  };
};

export default useCustomersListParams;
